@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Poppins", sans-serif;
}

html {
  scroll-behavior: smooth;
  transition: all 0.5s ease-in-out;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
